//监听滚动条事件
window.onscroll = function() {
  Limg()
}

//页面加载时调用一次，使图片显示
window.onload = function() {
  var img = document.querySelectorAll('img[data-src]')
  for (var i = 0; i < img.length; i++) {
    img[i].style.opacity = '0'
  }
  Limg()
}

function Limg() {
  var viewHeight = document.documentElement.clientHeight // 可视区域的高度
  var t = document.documentElement.scrollTop || document.body.scrollTop
  var limg = document.querySelectorAll('img[data-src]')
  // Array.prototype.forEach.call()是一种快速的方法访问forEach，并将空数组的this换成想要遍历的list
  Array.prototype.forEach.call(limg, function(item, index) {
    var rect
    if (item.getAttribute('data-src') === '') return
    //getBoundingClientRect用于获取某个元素相对于视窗的位置集合。集合中有top, right, bottom, left等属性。
    rect = item.getBoundingClientRect()
    // 图片一进入可视区，动态加载
    if (rect.bottom >= 0 && rect.top < viewHeight) {
      ;(function() {
        var img = new Image()
        img.src = item.getAttribute('data-src')
        item.src = img.src
        //给图片添加过渡效果，让图片显示
        var j = 0
        setInterval(function() {
          j += 0.2
          if (j <= 1) {
            item.style.opacity = j
            return
          }
        }, 100)
        item.removeAttribute('data-src')
      })()
    }
  })
}
